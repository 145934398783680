import { motion } from "framer-motion";
import React from "react";
import { ReactComponent as XIcon } from "../../../assets/home/xIcon.svg";
import NavButton from "./NavButton";

const menuVariants = {
  hidden: { width: "0%", opacity: 0 },
  visible: { width: "auto", opacity: 1 },
};
const bgVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const transition = { duration: 0.5, easing: "linear" };
function MobileNavMenu({
  refs,
  navLabels,
  handleScroll,
  setMenuIsOpen,
  menuIsOpen,
  handleOpenPage,
  pageIndex,
}) {
  const renderButtons = () => {
    return refs.map((ref, i) => {
      return (
        <NavButton
          label={navLabels[i]}
          key={i}
          isSelected={i === pageIndex}
          onClick={(e) => handleScroll(e, ref)}
          style={{ textAlign: "left", color: "#CECECE", marginBottom: 12 }}
          selectedStyle={{ color: "#1E3F66" }}
        />
      );
    });
  };
  return (
    <motion.div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        background: "rgba(0,0,0,0.2)",
        backdropFilter: "blur(3px)",
        top: 0,
        right: 0,
        overflow: "hidden",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        pointerEvents: "none",
      }}
      variants={bgVariants}
      initial={"hidden"}
      animate={menuIsOpen ? "visible" : "hidden"}
      transition={transition}
    >
      <motion.div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          background: "white",
          pointerEvents: "auto",
        }}
        variants={menuVariants}
        initial={"hidden"}
        animate={menuIsOpen ? "visible" : "hidden"}
        transition={transition}
      >
        <button
          style={{
            background: "none",
            display: "flex",
            border: "none",
            flexShrink: 1,
            justifyContent: "flex-end",
            padding: 16,
          }}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <XIcon />
        </button>
        {renderButtons()}
        <button
          key={refs.length}
          onClick={handleOpenPage}
          className="nav-button"
          style={{
            textAlign: "left",
            color: "#1E95CF",
            marginBottom: 12,
            opacity: 1,
            whiteSpace: "nowrap",
          }}
        >
          Visit Momentum Solar
        </button>
      </motion.div>
    </motion.div>
  );
}

export default MobileNavMenu;
