import TX from "./TX";
import FL from "./FL";
import NJ from "./NJ";
import NY from "./NY";
import CT from "./CT";
import PA from "./PA";
import GA from "./GA";
import MA from "./MA";

const stateBorders = [TX, FL, NJ, NY, CT, PA, GA, MA];
export default stateBorders;
