import React from "react";
import styled from "styled-components";

import ProductsSlideshow from "./ProductsSlideshow";

function Products() {
  return (
    <div className="page-background">
      <section className="page-content-wrapper">
        <StyledProducts>
          <div className="header-wrapper">
            <h2>Momentum Core Product Offerings</h2>
            <p className="large-text">
              Momentum Roofing + Solar offers a wide variety of roofing types
              and installation processes.
            </p>
            <p className="large-text">
              We only work with trusted, well established partners.
            </p>
          </div>
          <ProductsSlideshow />
        </StyledProducts>
      </section>
    </div>
  );
}

export default Products;

const StyledProducts = styled.div`
  position: relative;
  min-height: 100vh;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header-wrapper {
    margin-bottom: 4em;
    z-index: 3000;

    h2 {
      margin-bottom: 24px;
    }
  }

  .background-gradient-left {
    height: 100%;
    min-width: 6.4%;
    background: -webkit-linear-gradient(0deg, #fff, transparent);
    position: absolute;
    z-index: 1000;
    left: 0;
  }

  .background-gradient-right {
    height: 100%;
    min-width: 6.4%;
    right: -1px;
    background: -webkit-linear-gradient(180deg, #fff, transparent);
    position: absolute;
    z-index: 1000;
  }
`;
