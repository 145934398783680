import React from "react";

import Background1 from "../../assets/home/Background1.png";
import Background2 from "../../assets/home/Background2.png";

import { motion } from "framer-motion";

const images = [Background1, Background2];

const DURATION = 3;

const xAnimationFrames = images.reduce(
  (frames, _, i) => [...frames, i * -100 + "vw", i * -100 + "vw"],
  []
);

const times = images.reduce((times, _, i) => {
  const step = 1 / images.length;

  return [...times, i * step, (i + 1) * step - step * 0.2];
}, []);

const animate = { x: xAnimationFrames };

const transition = {
  duration: DURATION * images.length,
  repeat: Infinity,
  repeatType: "reverse",
  times,
};

function BackgroundSlideshow() {
  const renderImages = () => {
    return images.map((src, i) => {
      return (
        <img
          key={"image" + i}
          src={src}
          alt="Momentum Solar workers mid-installation"
          style={{
            width: 100 / images.length + "%",
            height: "100vh",
            objectFit: "cover",
            verticalAlign: "bottom",
          }}
        />
      );
    });
  };

  return (
    <>
      <motion.div
        animate={animate}
        transition={transition}
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          width: images.length * 100 + "vw",
        }}
      >
        {renderImages()}
      </motion.div>
    </>
  );
}

export default BackgroundSlideshow;
