export default [
  { lat: 34.9974, lng: -85.6082 },
  { lat: 34.9906, lng: -84.7266 },
  { lat: 34.9895, lng: -84.158 },
  { lat: 34.9996, lng: -83.1088 },
  { lat: 34.9287, lng: -83.1418 },
  { lat: 34.8318, lng: -83.3025 },
  { lat: 34.7281, lng: -83.356 },
  { lat: 34.6569, lng: -83.308 },
  { lat: 34.5744, lng: -83.1528 },
  { lat: 34.4839, lng: -83.0072 },
  { lat: 34.4681, lng: -82.8918 },
  { lat: 34.4443, lng: -82.8589 },
  { lat: 34.2674, lng: -82.749 },
  { lat: 34.1254, lng: -82.6831 },
  { lat: 34.014, lng: -82.5952 },
  { lat: 33.8647, lng: -82.3988 },
  { lat: 33.7563, lng: -82.2505 },
  { lat: 33.6695, lng: -82.2217 },
  { lat: 33.5963, lng: -82.1558 },
  { lat: 33.5036, lng: -82.0432 },
  { lat: 33.3707, lng: -81.9484 },
  { lat: 33.2077, lng: -81.8303 },
  { lat: 33.1674, lng: -81.7795 },
  { lat: 33.1456, lng: -81.7424 },
  { lat: 33.0881, lng: -81.6078 },
  { lat: 33.0075, lng: -81.5034 },
  { lat: 32.9418, lng: -81.5089 },
  { lat: 32.6914, lng: -81.4142 },
  { lat: 32.5815, lng: -81.4087 },
  { lat: 32.5283, lng: -81.2769 },
  { lat: 32.4576, lng: -81.1945 },
  { lat: 32.3185, lng: -81.1642 },
  { lat: 32.2151, lng: -81.1436 },
  { lat: 32.1128, lng: -81.1134 },
  { lat: 32.0477, lng: -80.9225 },
  { lat: 32.05, lng: -80.696 },
  { lat: 31.8881, lng: -80.7289 },
  { lat: 31.4697, lng: -80.9665 },
  { lat: 30.9988, lng: -81.1011 },
  { lat: 30.7041, lng: -81.2288 },
  { lat: 30.7241, lng: -81.6023 },
  { lat: 30.7713, lng: -81.7657 },
  { lat: 30.8221, lng: -81.9498 },
  { lat: 30.756, lng: -82.0239 },
  { lat: 30.6379, lng: -82.0459 },
  { lat: 30.4866, lng: -82.0239 },
  { lat: 30.4309, lng: -82.0363 },
  { lat: 30.3575, lng: -82.061 },
  { lat: 30.3598, lng: -82.1585 },
  { lat: 30.3859, lng: -82.2025 },
  { lat: 30.4842, lng: -82.2148 },
  { lat: 30.5682, lng: -82.2162 },
  { lat: 30.6131, lng: -82.9688 },
  { lat: 30.7041, lng: -84.8639 },
  { lat: 30.7831, lng: -84.9257 },
  { lat: 30.9117, lng: -84.9586 },
  { lat: 30.9741, lng: -84.9985 },
  { lat: 31.1282, lng: -85.063 },
  { lat: 31.2116, lng: -85.107 },
  { lat: 31.5247, lng: -85.0493 },
  { lat: 31.8006, lng: -85.1358 },
  { lat: 31.9592, lng: -85.0919 },
  { lat: 32.157, lng: -85.0342 },
  { lat: 32.25, lng: -84.9023 },
  { lat: 32.3974, lng: -84.9628 },
  { lat: 32.5468, lng: -85.0342 },
  { lat: 32.6949, lng: -85.1001 },
  { lat: 32.8138, lng: -85.166 },
  { lat: 32.9833, lng: -85.2072 },
  { lat: 33.6512, lng: -85.3418 },
  { lat: 34.562, lng: -85.5231 },
  { lat: 34.9929, lng: -85.6068 },
];
