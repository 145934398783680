import React, { useState } from "react";
import styled from "styled-components";
import Button from "../Components/Button";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const phoneNumberAutoFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

const submitContactInfo = async (body) => {
  try {
    const res = await axios.post(
      "https://jpgcof7f9d.execute-api.us-east-1.amazonaws.com/prod/alert/emailmrs",
      body
    );
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function Contact() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactWasSent, setContactWasSent] = useState(false);
  const { status, error, mutate } = useMutation(submitContactInfo, {
    onSuccess: () => setPhoneNumber(),
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setContactWasSent(true);
    const formData = new FormData(e.target);
    const body = {
      contactName: formData.get("name").trim(),
      businessName: formData.get("business").trim(),
      phoneNumber: phoneNumber.replaceAll("-", "").trim(),
      contactEmail: formData.get("email").trim(),
    };
    mutate(body);
  };

  const onChange = (e) => {
    const targetValue = phoneNumberAutoFormat(e.target.value);
    setPhoneNumber(targetValue);
  };
  const renderForm = () => {
    let element;
    if (!contactWasSent) {
      element = (
        <>
          <input placeholder="Business Name*" name="business" required />
          <input placeholder="Contact Name*" name="name" required />
          <input
            placeholder="Contact Email*"
            name="email"
            type="email"
            required
          />
          <input
            placeholder="Phone Number*"
            type="tel"
            name="phone"
            required
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            value={phoneNumber}
            onChange={onChange}
          />
          <Button type={2} />
        </>
      );
    } else {
      switch (status) {
        default:
        case "loading":
          element = <h3 style={{ color: "lightgrey" }}>One moment...</h3>;

          break;
        case "success":
          element = (
            <>
              <h3>Your contact information was sent successfully!</h3>{" "}
              <Button
                label="Back"
                style={{ alignSelf: "center", marginTop: "1em" }}
                onClick={() => {
                  setContactWasSent(false);
                  setPhoneNumber();
                }}
              />
            </>
          );
          break;
        case "error":
          element = (
            <>
              <h3>Something went wrong</h3>
              <a
                href="https://www.momentumsolar.com/contact-us/"
                target="_blank"
                rel="noreferrer"
                style={{ textAlign: "center" }}
              >
                Get in touch another way
              </a>
              <p>{error?.message}</p>
              <Button
                label="Back"
                style={{ alignSelf: "center", marginTop: "1em" }}
                onClick={() => {
                  setContactWasSent(false);
                  setPhoneNumber();
                }}
              />
            </>
          );
          break;
      }
    }
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        enter={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className="section"
        onSubmit={handleSubmit}
        key={status}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {element}
      </motion.div>
    );
  };

  return (
    <section className="page-content-wrapper">
      <StyledContact>
        <div className="main">
          <AnimatePresence>
            <form className="section" onSubmit={handleSubmit}>
              <div className="header-wrapper">
                <h2>Contact Us</h2>
              </div>
              {renderForm()}
            </form>
          </AnimatePresence>
          {/* <div className="line" />

          <div className="section">
            <h2>Portal Login</h2>
            <Button type={2} label="Customers" />
            <div className="divider">
              <div className="horizontal-line" />
              <p>or</p>
              <div className="horizontal-line" />
            </div>
            <Button label="Homebuilders" />
            <div>
              <p className="login-footer">
                Don&apos;t have an account? <a href="_">Contact Us</a>
              </p>
            </div>
          </div> */}
        </div>
        <p className="footer">
          © 2022 A Momentum Solar | All Rights Reserved | *Subject to applicable
          terms and conditions. Eligibility and savings is determined by
          municipal utility, roof condition and roof space, azimuth, tree
          location, shading, weather and other factors. HIC # – CA: 1026366; CT:
          HIC.0646323; FL: CVC57036; MA: 192204; NV: #0089130; NJ: 13VH05539000;
          NYC: 2042828-DCA; PA: PA131435; TX: 31915; MA: 192204; AZ: 237781
        </p>
      </StyledContact>
    </section>
  );
}

export default Contact;

const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
    min-width: 50%;

    h2 {
      margin-bottom: 1em;
    }
  }

  .line {
    height: 300px;
    min-width: 1px;
    width: 1px;
    background: #d6d6d6;
  }

  .divider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0.5em 0;

    .horizontal-line {
      height: 1px;
      width: 64px;
      background: #d6d6d6;
    }

    p {
      margin: 0 8px;
      margin-bottom: 6px;
    }
  }

  .login-footer {
    margin-top: 1em;
  }

  .footer {
    font-size: 10px;
    margin-top: 6em;
    margin-bottom: 4px;
    max-width: 1100px;
    text-align: center;
    align-self: flex-end;
  }

  input {
    min-width: 250px;
    padding: 16px;
    margin: 4px 0;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
  }

  button {
    width: 284px;
    margin: 4px 0;
  }

  @media (max-width: 768px) {
    .main {
      flex-direction: column;
    }

    .line {
      height: 1px;
      width: 100%;
      margin: 2em 0;
    }
  }
`;
