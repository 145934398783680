import React from "react";

import { motion } from "framer-motion";

function Slideshow({ icons = [] }) {
  const DURATION = window.innerWidth > 768 ? 10 : 5;
  const STAGGER = DURATION / (window.innerWidth > 768 ? 5 : 2);

  const renderIcons = () =>
    icons.map(({ src, name }, i) => {
      return (
        <motion.div
          variants={item}
          className="slide"
          transition={{
            repeat: Infinity,
            duration: DURATION,
            repeatDelay: icons.length * STAGGER - DURATION,
            ease: "linear",
          }}
          style={{ position: "absolute" }}
          key={i}
        >
          <img src={src} alt={name ? `${name} logo` : "One of our Partners!"} />
        </motion.div>
      );
    });

  const item = {
    hidden: { left: window.innerWidth > 768 ? "-300px" : "-100%", opacity: 1 },
    show: { left: "150%", opacity: 1 },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="show"
        transition={{
          staggerChildren: STAGGER,
        }}
        className="slides-wrapper"
      >
        {renderIcons()}
      </motion.div>
    </>
  );
}

export default Slideshow;
