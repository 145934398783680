import React from "react";
import styled from "styled-components";

import Button from "../../Components/Button";
import BackgroundSlideshow from "./BackgroundSlideshow";

function Home({ navRefs }) {
  const handleClick = (navRef) => {
    navRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="page-background">
      <BackgroundSlideshow />
      <section className="page-content-wrapper" style={{ background: "#0003" }}>
        <StyledHome>
          <h1>Helping you build dream homes</h1>
          <h1>where roofing & solar comes standard</h1>
          <div className="buttons-wrapper">
            <Button
              onClick={() => handleClick(navRefs[1])}
              label="Learn More"
              style={{ background: "#fff" }}
            />
            <Button
              onClick={() => handleClick(navRefs[6])}
              label="Get Started"
              type={2}
            />
          </div>
        </StyledHome>
      </section>
    </div>
  );
}

export default Home;

const StyledHome = styled.div`
  margin: auto;
  margin-top: 10em;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: start;

  h1 {
    text-align: center;
  }

  .buttons-wrapper {
    display: flex;
    margin-top: 60px;
    flex-wrap: wrap;

    button {
      flex: 1;
      margin: 4px 16px;
    }
  }
`;
