// Most of NY is commented out. Currently just highlights Long Island
const NY = [
  { lat: 42.5142, lng: -79.7624 },
  { lat: 42.7783, lng: -79.0672 },
  { lat: 42.8508, lng: -78.9313 },
  { lat: 42.9061, lng: -78.9024 },
  { lat: 42.9554, lng: -78.9313 },
  { lat: 42.9584, lng: -78.9656 },
  { lat: 42.9886, lng: -79.0219 },
  { lat: 43.0568, lng: -79.0027 },
  { lat: 43.0769, lng: -79.0727 },
  { lat: 43.122, lng: -79.0713 },
  { lat: 43.1441, lng: -79.0302 },
  { lat: 43.1801, lng: -79.0576 },
  { lat: 43.2482, lng: -79.0604 },
  { lat: 43.2812, lng: -79.0837 },
  { lat: 43.4509, lng: -79.2004 },
  { lat: 43.6311, lng: -78.6909 },
  { lat: 43.6321, lng: -76.7958 },
  { lat: 43.9987, lng: -76.4978 },
  { lat: 44.0965, lng: -76.4388 },
  { lat: 44.1349, lng: -76.3536 },
  { lat: 44.1989, lng: -76.3124 },
  { lat: 44.2049, lng: -76.2437 },
  { lat: 44.2413, lng: -76.1655 },
  { lat: 44.2973, lng: -76.1353 },
  { lat: 44.3327, lng: -76.0474 },
  { lat: 44.3553, lng: -75.9856 },
  { lat: 44.3749, lng: -75.9196 },
  { lat: 44.3994, lng: -75.873 },
  { lat: 44.4308, lng: -75.8221 },
  { lat: 44.474, lng: -75.8098 },
  { lat: 44.5425, lng: -75.7288 },
  { lat: 44.6647, lng: -75.5585 },
  { lat: 44.7672, lng: -75.4088 },
  { lat: 44.8101, lng: -75.3442 },
  { lat: 44.8383, lng: -75.3058 },
  { lat: 44.8676, lng: -75.2399 },
  { lat: 44.9211, lng: -75.1204 },
  { lat: 44.9609, lng: -74.9995 },
  { lat: 44.9803, lng: -74.9899 },
  { lat: 44.9852, lng: -74.9103 },
  { lat: 45.0017, lng: -74.8856 },
  { lat: 45.0153, lng: -74.8306 },
  { lat: 45.0046, lng: -74.7633 },
  { lat: 45.0027, lng: -74.707 },
  { lat: 45.0007, lng: -74.5642 },
  { lat: 44.992, lng: -74.1467 },
  { lat: 45.0037, lng: -73.7306 },
  { lat: 45.0085, lng: -73.4203 },
  { lat: 45.0109, lng: -73.343 },
  { lat: 44.9874, lng: -73.3547 },
  { lat: 44.9648, lng: -73.3379 },
  { lat: 44.916, lng: -73.3396 },
  { lat: 44.8354, lng: -73.3739 },
  { lat: 44.8013, lng: -73.3324 },
  { lat: 44.7419, lng: -73.3667 },
  { lat: 44.6139, lng: -73.3873 },
  { lat: 44.5787, lng: -73.3736 },
  { lat: 44.4916, lng: -73.3049 },
  { lat: 44.4289, lng: -73.2953 },
  { lat: 44.3513, lng: -73.3365 },
  { lat: 44.2757, lng: -73.3118 },
  { lat: 44.198, lng: -73.3818 },
  { lat: 44.1142, lng: -73.4079 },
  { lat: 44.0511, lng: -73.4367 },
  { lat: 44.0165, lng: -73.4065 },
  { lat: 43.9375, lng: -73.4079 },
  { lat: 43.8771, lng: -73.3749 },
  { lat: 43.8167, lng: -73.3914 },
  { lat: 43.779, lng: -73.3557 },
  { lat: 43.646, lng: -73.4244 },
  { lat: 43.5893, lng: -73.434 },
  { lat: 43.5655, lng: -73.3969 },
  { lat: 43.6112, lng: -73.3818 },
  { lat: 43.6271, lng: -73.3049 },
  { lat: 43.5764, lng: -73.3063 },
  { lat: 43.5675, lng: -73.2582 },
  { lat: 43.5227, lng: -73.2445 },
  { lat: 43.2582, lng: -73.2582 },
  { lat: 42.9715, lng: -73.2733 },
  { lat: 42.8004, lng: -73.2898 },
  { lat: 42.746, lng: -73.2664 },
  { lat: 42.463, lng: -73.3708 },
  { lat: 42.084, lng: -73.5095 },
  { lat: 42.0218, lng: -73.4903 },
  { lat: 41.8808, lng: -73.4999 },
  { lat: 41.2953, lng: -73.5535 }, // Comment out from start to here for just Long island
  { lat: 41.2128, lng: -73.4834 },
  { lat: 41.1011, lng: -73.7275 },
  { lat: 41.0237, lng: -73.6644 },
  { lat: 40.9851, lng: -73.6578 },
  { lat: 40.9509, lng: -73.6132 },
  { lat: 41.1869, lng: -72.4823 },
  { lat: 41.2551, lng: -72.095 },
  { lat: 41.3005, lng: -71.9714 },
  { lat: 41.3108, lng: -71.9193 },
  { lat: 41.1838, lng: -71.7915 },
  { lat: 41.1249, lng: -71.7929 },
  { lat: 41.0462, lng: -71.7517 },
  { lat: 40.6306, lng: -72.9465 },
  { lat: 40.5368, lng: -73.4628 },
  { lat: 40.4887, lng: -73.8885 },
  { lat: 40.5232, lng: -73.949 },
  { lat: 40.4772, lng: -74.2271 },
  { lat: 40.4861, lng: -74.2532 },
  { lat: 40.6468, lng: -74.1866 },
  { lat: 40.6556, lng: -74.0547 },
  { lat: 40.7618, lng: -74.0156 },
  { lat: 40.8699, lng: -73.9421 },
  { lat: 40.998, lng: -73.8934 },
  { lat: 41.0343, lng: -73.9854 }, //Comment this out to the end for just Long Island
  { lat: 41.3268, lng: -74.6274 },
  { lat: 41.3583, lng: -74.7084 },
  { lat: 41.3811, lng: -74.7101 },
  { lat: 41.4386, lng: -74.8265 },
  { lat: 41.5075, lng: -74.9913 },
  { lat: 41.6, lng: -75.0668 },
  { lat: 41.6719, lng: -75.0366 },
  { lat: 41.7672, lng: -75.0545 },
  { lat: 41.8808, lng: -75.1945 },
  { lat: 42.0013, lng: -75.3552 },
  { lat: 42.0003, lng: -75.4266 },
  { lat: 42.0013, lng: -77.0306 },
  { lat: 41.9993, lng: -79.725 },
  { lat: 42.0003, lng: -79.7621 },
  { lat: 42.1827, lng: -79.7621 },
  { lat: 42.5146, lng: -79.7621 },
];

export default NY;
