import React, { useState } from "react";
import { ReactComponent as MHSLogo } from "../../../assets/home/MHSLogo.svg";
import { ReactComponent as NavMenuIcon } from "../../../assets/home/NavMenuIcon.svg";
import { ReactComponent as UpChevron } from "../../../assets/home/UpChevron.svg";
import Button from "../../../Components/Button";
import MobileNavMenu from "./MobileNavMenu";

function MobileNav({
  handleOpenPage,
  pageIndex,
  handleScroll,
  refs,
  ...props
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "50px",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Button
        label="Visit Momentum Solar"
        onClick={handleOpenPage}
        style={{
          padding: "4px 4px",
          borderWidth: 1,
          fontSize: "12px",
          margin: "0px 16px",
          flex: 1,
        }}
        color="#fff"
      />
      <MHSLogo height={25} width={57} style={{ color: "#fff" }} />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          margin: "0px 16px",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <button
          style={{ background: "none", border: "none" }}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <NavMenuIcon style={{ color: "#fff" }} />
        </button>
      </div>
      <MobileNavMenu
        {...props}
        handleScroll={handleScroll}
        refs={refs}
        pageIndex={pageIndex}
        setMenuIsOpen={setMenuIsOpen}
        menuIsOpen={menuIsOpen}
      />
      {pageIndex >= 0 && (
        <button
          onClick={(e) => handleScroll(e, refs[0])}
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            padding: 16,
            border: "none",
            background: "transparent",
          }}
        >
          <UpChevron />
        </button>
      )}
    </div>
  );
}

export default MobileNav;
