const TX = [
  { lat: 31.8659, lng: -106.5715 },
  { lat: 31.7504, lng: -106.5042 },
  { lat: 31.6242, lng: -106.3092 },
  { lat: 31.4638, lng: -106.2103 },
  { lat: 31.3912, lng: -106.0181 },
  { lat: 31.1846, lng: -105.7874 },
  { lat: 31.0012, lng: -105.5663 },
  { lat: 30.8456, lng: -105.4015 },
  { lat: 30.6462, lng: -105.0032 },
  { lat: 30.3847, lng: -104.8521 },
  { lat: 30.2591, lng: -104.7437 },
  { lat: 30.0738, lng: -104.6915 },
  { lat: 29.9169, lng: -104.6777 },
  { lat: 29.7644, lng: -104.5679 },
  { lat: 29.6475, lng: -104.528 },
  { lat: 29.5603, lng: -104.4044 },
  { lat: 29.4719, lng: -104.2067 },
  { lat: 29.3834, lng: -104.1559 },
  { lat: 29.2948, lng: -103.9774 },
  { lat: 29.2804, lng: -103.9128 },
  { lat: 29.2481, lng: -103.8208 },
  { lat: 29.1378, lng: -103.564 },
  { lat: 29.0682, lng: -103.4692 },
  { lat: 29.0105, lng: -103.3154 },
  { lat: 28.9601, lng: -103.1616 },
  { lat: 29.0177, lng: -103.0957 },
  { lat: 29.133, lng: -103.0298 },
  { lat: 29.2157, lng: -102.8677 },
  { lat: 29.2565, lng: -102.8979 },
  { lat: 29.357, lng: -102.8375 },
  { lat: 29.4898, lng: -102.8004 },
  { lat: 29.6881, lng: -102.7002 },
  { lat: 29.7691, lng: -102.5134 },
  { lat: 29.7596, lng: -102.3843 },
  { lat: 29.8788, lng: -102.3047 },
  { lat: 29.7834, lng: -102.1509 },
  { lat: 29.7572, lng: -101.7004 },
  { lat: 29.7644, lng: -101.4917 },
  { lat: 29.6308, lng: -101.2939 },
  { lat: 29.5269, lng: -101.2582 },
  { lat: 29.3642, lng: -101.0056 },
  { lat: 29.3056, lng: -100.9204 },
  { lat: 29.1642, lng: -100.7707 },
  { lat: 29.0946, lng: -100.7007 },
  { lat: 28.9012, lng: -100.6306 },
  { lat: 28.6593, lng: -100.4974 },
  { lat: 28.4675, lng: -100.3601 },
  { lat: 28.2778, lng: -100.2969 },
  { lat: 28.1882, lng: -100.1733 },
  { lat: 28.0526, lng: -100.0195 },
  { lat: 27.9435, lng: -99.9344 },
  { lat: 27.7638, lng: -99.8438 },
  { lat: 27.6641, lng: -99.7119 },
  { lat: 27.4839, lng: -99.4812 },
  { lat: 27.3059, lng: -99.5375 },
  { lat: 27.1948, lng: -99.429 },
  { lat: 27.0175, lng: -99.4455 },
  { lat: 26.8829, lng: -99.3164 },
  { lat: 26.6867, lng: -99.2065 },
  { lat: 26.4116, lng: -99.0967 },
  { lat: 26.3574, lng: -98.8138 },
  { lat: 26.2257, lng: -98.6668 },
  { lat: 26.2343, lng: -98.5474 },
  { lat: 26.1357, lng: -98.3276 },
  { lat: 26.0457, lng: -98.1697 },
  { lat: 26.0518, lng: -97.9143 },
  { lat: 26.005, lng: -97.6643 },
  { lat: 25.8419, lng: -97.402 },
  { lat: 25.9074, lng: -97.3526 },
  { lat: 25.9679, lng: -97.0148 },
  { lat: 26.1789, lng: -97.0697 },
  { lat: 26.8253, lng: -97.2249 },
  { lat: 27.423, lng: -97.0752 },
  { lat: 28.0599, lng: -96.6096 },
  { lat: 28.4228, lng: -95.9285 },
  { lat: 28.7568, lng: -95.3036 },
  { lat: 29.0742, lng: -94.7296 },
  { lat: 29.381, lng: -94.3355 },
  { lat: 29.6021, lng: -93.8205 },
  { lat: 29.8013, lng: -93.9317 },
  { lat: 29.9157, lng: -93.8136 },
  { lat: 30.0489, lng: -93.723 },
  { lat: 30.1214, lng: -93.6996 },
  { lat: 30.2021, lng: -93.7216 },
  { lat: 30.2792, lng: -93.7038 },
  { lat: 30.3278, lng: -93.7628 },
  { lat: 30.3835, lng: -93.7587 },
  { lat: 30.438, lng: -93.701 },
  { lat: 30.5079, lng: -93.7024 },
  { lat: 30.5362, lng: -93.7299 },
  { lat: 30.6296, lng: -93.6694 },
  { lat: 30.7466, lng: -93.609 },
  { lat: 30.8114, lng: -93.5527 },
  { lat: 30.8834, lng: -93.5747 },
  { lat: 30.9376, lng: -93.5307 },
  { lat: 31.0318, lng: -93.5074 },
  { lat: 31.0812, lng: -93.5266 },
  { lat: 31.1787, lng: -93.5335 },
  { lat: 31.167, lng: -93.598 },
  { lat: 31.3055, lng: -93.6832 },
  { lat: 31.383, lng: -93.6708 },
  { lat: 31.4369, lng: -93.6887 },
  { lat: 31.5107, lng: -93.7202 },
  { lat: 31.582, lng: -93.8315 },
  { lat: 31.644, lng: -93.8123 },
  { lat: 31.7188, lng: -93.8232 },
  { lat: 31.7936, lng: -93.8342 },
  { lat: 31.8309, lng: -93.8782 },
  { lat: 31.8869, lng: -93.9221 },
  { lat: 31.9335, lng: -93.9661 },
  { lat: 32.0081, lng: -94.043 },
  { lat: 33.4681, lng: -94.043 },
  { lat: 33.5414, lng: -94.043 },
  { lat: 33.5689, lng: -94.1528 },
  { lat: 33.5872, lng: -94.1968 },
  { lat: 33.5872, lng: -94.2627 },
  { lat: 33.5689, lng: -94.3176 },
  { lat: 33.5597, lng: -94.3945 },
  { lat: 33.578, lng: -94.4275 },
  { lat: 33.6055, lng: -94.4275 },
  { lat: 33.6421, lng: -94.4495 },
  { lat: 33.6329, lng: -94.4879 },
  { lat: 33.6421, lng: -94.5236 },
  { lat: 33.6695, lng: -94.6637 },
  { lat: 33.7061, lng: -94.7461 },
  { lat: 33.7791, lng: -94.8999 },
  { lat: 33.8818, lng: -95.0757 },
  { lat: 33.9251, lng: -95.1526 },
  { lat: 33.9604, lng: -95.2254 },
  { lat: 33.875, lng: -95.2858 },
  { lat: 33.8841, lng: -95.5399 },
  { lat: 33.8887, lng: -95.7568 },
  { lat: 33.8408, lng: -95.842 },
  { lat: 33.8556, lng: -96.0274 },
  { lat: 33.6901, lng: -96.3528 },
  { lat: 33.8442, lng: -96.6179 },
  { lat: 33.8898, lng: -96.5836 },
  { lat: 33.8955, lng: -96.6673 },
  { lat: 33.8179, lng: -96.7538 },
  { lat: 33.8613, lng: -96.8335 },
  { lat: 33.8613, lng: -96.8774 },
  { lat: 33.9388, lng: -96.9159 },
  { lat: 33.7392, lng: -97.0917 },
  { lat: 33.7449, lng: -97.1645 },
  { lat: 33.8978, lng: -97.218 },
  { lat: 33.8225, lng: -97.3746 },
  { lat: 33.8305, lng: -97.4611 },
  { lat: 33.8761, lng: -97.446 },
  { lat: 33.9798, lng: -97.6945 },
  { lat: 33.8476, lng: -97.8648 },
  { lat: 33.8978, lng: -97.9651 },
  { lat: 34.0299, lng: -98.0983 },
  { lat: 34.1141, lng: -98.1752 },
  { lat: 34.1425, lng: -98.3743 },
  { lat: 34.064, lng: -98.4773 },
  { lat: 34.1209, lng: -98.5529 },
  { lat: 34.1232, lng: -98.752 },
  { lat: 34.2095, lng: -98.9539 },
  { lat: 34.2073, lng: -99.0637 },
  { lat: 34.2141, lng: -99.1832 },
  { lat: 34.3593, lng: -99.2505 },
  { lat: 34.4613, lng: -99.3823 },
  { lat: 34.3774, lng: -99.4318 },
  { lat: 34.416, lng: -99.5718 },
  { lat: 34.3706, lng: -99.6158 },
  { lat: 34.4726, lng: -99.8094 },
  { lat: 34.5631, lng: -99.9934 },
  { lat: 36.4975, lng: -100.0017 },
  { lat: 36.5008, lng: -103.0408 },
  { lat: 32.0011, lng: -103.0655 },
  { lat: 32.0023, lng: -106.6168 },
];
export default TX;
