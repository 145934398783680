import React, { forwardRef } from "react";
import styled from "styled-components";

const ScrollPageWrapper = forwardRef(function ScrollPage({ children }, ref) {
  return <StyledPageWrapper ref={ref}>{children}</StyledPageWrapper>;
});

const StyledPageWrapper = styled.div`
  min-height: 100vh;
  max-width: 100%;
  position: relative;
  scroll-snap-align: start;

  @media (max-width: 768px) {
    scroll-snap-align: none;
  }
  .header-wrapper {
    margin-top: 8em;
    text-align: center;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 20px;
    }

    .header-wrapper {
      margin-top: 4em;
    }
  }

  .page-content-wrapper {
    min-height: 100vh;
    max-width: 1620;

    padding: 0 6.4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: relative;
  }

  .page-background {
    min-height: 100vh;
    width: 100%;
    maxwidth: 100%;
    position: relative;
    overflow-x: hidden;
  }
`;
export default ScrollPageWrapper;
