import React, { useState } from "react";

import { ReactComponent as MHSLogo } from "../../../assets/home/MHSLogo.svg";
import { ReactComponent as MHSLogo_FullColor } from "../../../assets/home/MHSLogo_FullColor.svg";
import Button from "../../../Components/Button";
import MobileNav from "./MobileNav";
import NavButton from "./NavButton";

import StyledNav from "./styles";

const navLabels = [
  "Home",
  "Benefits",
  "Products",
  "Partners",
  "Safety",
  "Locations",
  "Contact",
];

const handleOpenPage = () => {
  window.open("https://www.momentumsolar.com/");
};

const htmlElement = document.querySelector("html");

let previousScrollTimeout;
const handleScroll = (e, scrollRef) => {
  e.preventDefault();
  // On mobile browsers, scrollSnap and scrollIntoView conflict.
  // This solution briefly disables scrollSnap while scrolling and re-enables
  // It after an interval.
  previousScrollTimeout && clearTimeout(previousScrollTimeout);
  htmlElement.style.scrollSnapType = "y none";
  previousScrollTimeout = setTimeout(
    () => (htmlElement.style.scrollSnapType = "y proximity"),
    500
  );

  scrollRef.current.scrollIntoView({ behavior: "smooth" });
};

function Nav({ refs }) {
  const [pageIndex, setPageIndex] = useState(0);

  window.addEventListener("scroll", () => {
    setPageIndex(Math.floor(window.pageYOffset / window.innerHeight));
  });

  const renderButtons = () => {
    return refs.map((ref, i) => {
      return (
        <NavButton
          label={navLabels[i]}
          key={i}
          isSelected={i === pageIndex}
          onClick={(e) => handleScroll(e, ref)}
        />
      );
    });
  };
  const getBackgroundColor = (pageIndex) => {
    switch (pageIndex) {
      case 0:
        return "transparent";
      case 1:
        return "rgb(245, 245, 245)";
      case 3:
        return "rgb(250, 250, 250)";
      case 4:
        return "rgb(247, 252, 255)";
      default:
        return "#fff";
    }
  };
  return (
    <StyledNav>
      {window.innerWidth > 860 ? (
        <div
          className="nav-wrapper"
          style={{ color: pageIndex > 0 ? "#1e3f66" : "#fff" }}
        >
          <div className="logo-wrapper">
            {pageIndex > 0 ? (
              <MHSLogo_FullColor
                width="100%"
                height="100%"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  margin: "0",
                  color: "#fff",
                }}
              />
            ) : (
              <MHSLogo
                width="100%"
                height="100%"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  margin: "0",
                  color: "#fff",
                }}
              />
            )}
          </div>

          <div className="nav-buttons-wrapper ">
            {renderButtons()}

            <div className="big-button-wrapper">
              <Button
                label={"Visit Momentum Solar"}
                color={pageIndex > 0 ? "#1E95CF" : "#fff"}
                style={{
                  margin: 16,
                  background: getBackgroundColor(pageIndex),
                }}
                onClick={handleOpenPage}
              />
            </div>
          </div>
        </div>
      ) : (
        <MobileNav
          handleOpenPage={handleOpenPage}
          handleScroll={handleScroll}
          refs={refs}
          navLabels={navLabels}
          pageIndex={pageIndex}
        />
      )}
    </StyledNav>
  );
}

export default Nav;
