const CT = [
  { lat: 42.0498, lng: -73.4875 },
  { lat: 42.0511, lng: -73.4247 },
  { lat: 42.0371, lng: -72.8146 },
  { lat: 41.9983, lng: -72.8174 },
  { lat: 42.0044, lng: -72.7638 },
  { lat: 42.036, lng: -72.7563 },
  { lat: 42.0368, lng: -72.6945 },
  { lat: 42.0309, lng: -72.6086 },
  { lat: 42.0269, lng: -72.6059 },
  { lat: 42.0269, lng: -72.5784 },
  { lat: 42.035, lng: -72.5729 },
  { lat: 42.035, lng: -72.4026 },
  { lat: 42.0248, lng: -71.7984 },
  { lat: 41.6832, lng: -71.7874 },
  { lat: 41.4165, lng: -71.7984 },
  { lat: 41.3892, lng: -71.8341 },
  { lat: 41.3273, lng: -71.8526 },
  { lat: 41.3309, lng: -71.8938 },
  { lat: 41.3103, lng: -71.9302 },
  { lat: 41.2907, lng: -72.0195 },
  { lat: 41.2618, lng: -72.0827 },
  { lat: 41.1962, lng: -72.4322 },
  { lat: 41.0866, lng: -73.0007 },
  { lat: 41.0255, lng: -73.2493 },
  { lat: 40.9509, lng: -73.6132 },
  { lat: 40.983, lng: -73.6606 },
  { lat: 41.0338, lng: -73.6723 },
  { lat: 41.1011, lng: -73.7272 },
  { lat: 41.2153, lng: -73.4834 },
  { lat: 41.2953, lng: -73.5507 },
  { lat: 41.4906, lng: -73.5329 },
  { lat: 42.0493, lng: -73.4875 },
];

export default CT;
