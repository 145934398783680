import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import content from "./content";
import ProductButton from "./ProductButton";
import Slideshow from "./Slideshow";

const transition = {
  duration: 0.8,
};

const getPreviousWidth = (index, refs) => {
  let previousWidth = 0;
  for (let i = 0; i <= index; i++) {
    previousWidth += refs.current[i]?.getBoundingClientRect().width;
  }
  return previousWidth;
};

function ProductsSlideshow() {
  const [activeIndex, setActiveIndex] = useState(0);
  const buttonRefs = useRef([]);

  const renderProductButtons = () => {
    return content.map(({ label }, i) => {
      return (
        <ProductButton
          label={label}
          index={i}
          key={i}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          buttonRefs={buttonRefs}
        />
      );
    });
  };

  return (
    <>
      <div className="background-gradient-left" />
      <div className="background-gradient-right" />
      <StyledSlideshow>
        <motion.div
          animate={() => ({
            x: `calc(50% - ${
              buttonRefs.current[activeIndex]?.getBoundingClientRect().width / 2
            }px)`,
          })}
          transition={transition}
        >
          <motion.div
            animate={{
              x: getPreviousWidth(activeIndex - 1, buttonRefs) * -1,
            }}
            transition={transition}
            style={{
              display: "flex",
              width: "auto",
              flexDirection: "row",
            }}
          >
            {renderProductButtons()}
          </motion.div>
        </motion.div>

        <AnimatePresence>
          <motion.div
            key={activeIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            enter={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ position: "absolute", width: "100%" }}
          >
            <Slideshow icons={content[activeIndex].icons} />
          </motion.div>
        </AnimatePresence>
      </StyledSlideshow>
    </>
  );
}

export default ProductsSlideshow;

const StyledSlideshow = styled.div`
  width: 100%;
  position: relative;
  min-height: 30vh;

  .product-button {
    background: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    margin: 0 1em;

    @media (max-width: 820px) {
      margin: 0;
    }

    h4 {
      white-space: nowrap;
    }
  }

  .product-button:hover {
    opacity: 0.6;
  }

  .underline {
    height: 3px;
    width: 0%;
    // background: #1e95cf;
    background: -webkit-linear-gradient(0deg, #ff3, #f7931e);
    background: linear-gradient(90deg, #ff3, #f7931e);
    margin-top: 4px;
  }

  .slides-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    max-width: 100%;
    min-width: 100%;

    margin-top: 4em;

    position: absolute;
  }

  .slide {
    height: 124px;
    width: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      min-width: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
`;
