import styled from "styled-components";
export default styled.nav`
  top: 0;
  font-family: LatoBold;
  color: #fff;
  width: 100%;
  position: fixed;
  z-index: 10000;

  .underline {
    height: 3px;
    // background: #1e95cf;
    background: -webkit-linear-gradient(0deg, #ff3, #f7931e);
    background: linear-gradient(90deg, #ff3, #f7931e);
    margin-top: 4px;
  }

  .nav-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 6.4%;
    padding-top: 2em;
  }

  .logo-wrapper {
    height: 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }

  .nav-button {
    border: inherit;
    background: inherit;
    color: inherit;
    font-family: inherit;
    list-style-type: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    padding: 0 16px;
    font-size: 1rem;
  }

  .nav-button:hover {
    opacity: 0.6;
  }

  @media (max-width: 860px) {
    background: rgba(0, 0, 0, 0.2);
  }
`;
