import React, { useEffect, useRef } from "react";

import { motion } from "framer-motion";

const variants = {
  hidden: { width: "0%" },
  visible: { width: "100%" },
};

const transition = { duration: 0.8 };

function ProductButton({
  activeIndex,
  index,
  label,
  setActiveIndex,
  buttonRefs,
}) {
  const ref = useRef();

  useEffect(() => {
    buttonRefs.current[index] = ref.current;
  }, [activeIndex, buttonRefs]);

  return (
    <button
      className={`product-button `}
      style={{
        display: "flex",
      }}
      onClick={() => setActiveIndex(index)}
      ref={ref}
    >
      <h4 style={{ color: activeIndex === index ? "#1E3F66" : "#1E95CF" }}>
        {label}
      </h4>
      <motion.div
        variants={variants}
        animate={activeIndex === index ? "visible" : "hidden"}
        className="underline"
        transition={transition}
      />
    </button>
  );
}

export default ProductButton;
