import React from "react";

function BenefitItem({ content }) {
  const { icon, header, text } = content;
  return (
    <div className="item-wrapper">
      <div className="icon-wrapper">{icon}</div>
      <div className="text-wrapper">
        <h3>{header}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default BenefitItem;
