import React from "react";
import { ReactComponent as Icon1 } from "../../assets/benefits/Icon1.svg";
import { ReactComponent as Icon2 } from "../../assets/benefits/Icon2.svg";
import { ReactComponent as Icon3 } from "../../assets/benefits/Icon3.svg";
import { ReactComponent as Icon4 } from "../../assets/benefits/Icon4.svg";
import { ReactComponent as Icon5 } from "../../assets/benefits/Icon5.svg";
import { ReactComponent as Icon6 } from "../../assets/benefits/Icon6.svg";

const size = { width: "100%", height: "100%" };

const content = [
  {
    icon: <Icon1 {...size} />,
    header: "High Quality\nEquipment",
    text: "Momentum’s extensive supply chain connections makes it easy to provide top-tier, thoroughly vetted products at a competitive price.",
  },
  {
    icon: <Icon2 {...size} />,
    header: "Installing During\nConstruction",
    text: "In the construction business, we know that time is money. That’s why we’ll work simultaneously with your homebuild to ensure that the project goes as smoothly and quickly as possible.",
  },
  {
    icon: <Icon3 {...size} />,
    header: "Local Expertise",
    text: "Our nationwide hubs means that you’ll always speak to someone who knows the area and can handle your project at the drop of a hat.",
  },
  {
    icon: <Icon4 {...size} />,
    header: "10+ Years of\nService",
    text: "With more than 10 years of business under our belt, we know how to deliver on our promises and make sure the delivery, activation and maintenance of our systems is one less thing for you to worry about.",
  },
  {
    icon: <Icon5 {...size} />,
    header: "Comprehensive\nService Offerings",
    text: "Solar is what we specialize in. For more than a decade, Momentum has been synonymous with white-glove, best-in-class solar service. Our collection of products includes tier-one panels, battery storage and system monitoring after installation.",
  },
  {
    icon: <Icon6 {...size} />,
    header: "Additional Product\nOfferings",
    text: "We offer a wide selection of additional products for our customers, including Architectural wall panels, Metal and composite roofing systems, Commercial solar power systems, Lease and PPA in-house financing.",
  },
];

export default content;
