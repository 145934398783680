const NJ = [
  { lat: 41.3572, lng: -74.695 },
  { lat: 41.3394, lng: -74.6559 },
  { lat: 40.9934, lng: -73.894 },
  { lat: 40.8398, lng: -73.9586 },
  { lat: 40.7691, lng: -74.0094 },
  { lat: 40.6994, lng: -74.0231 },
  { lat: 40.6786, lng: -74.0437 },
  { lat: 40.6515, lng: -74.0808 },
  { lat: 40.6421, lng: -74.1357 },
  { lat: 40.6452, lng: -74.1962 },
  { lat: 40.5952, lng: -74.2003 },
  { lat: 40.5566, lng: -74.2195 },
  { lat: 40.4877, lng: -74.2552 },
  { lat: 40.4762, lng: -74.2264 },
  { lat: 40.5253, lng: -73.9503 },
  { lat: 40.4846, lng: -73.8885 },
  { lat: 40.0045, lng: -73.9352 },
  { lat: 39.6131, lng: -74.041 },
  { lat: 39.4744, lng: -74.2209 },
  { lat: 38.9882, lng: -74.6713 },
  { lat: 38.8664, lng: -74.8553 },
  { lat: 38.8472, lng: -75.0476 },
  { lat: 39.0565, lng: -75.1685 },
  { lat: 39.2525, lng: -75.325 },
  { lat: 39.45, lng: -75.5544 },
  { lat: 39.4966, lng: -75.5612 },
  { lat: 39.4998, lng: -75.5283 },
  { lat: 39.5411, lng: -75.5338 },
  { lat: 39.5761, lng: -75.509 },
  { lat: 39.6237, lng: -75.5708 },
  { lat: 39.6713, lng: -75.5104 },
  { lat: 39.7167, lng: -75.4843 },
  { lat: 39.8033, lng: -75.4156 },
  { lat: 39.836, lng: -75.2632 },
  { lat: 39.8823, lng: -75.1918 },
  { lat: 40.118, lng: -74.7922 },
  { lat: 40.139, lng: -74.7331 },
  { lat: 40.2565, lng: -74.8485 },
  { lat: 40.3361, lng: -74.9419 },
  { lat: 40.402, lng: -74.9721 },
  { lat: 40.424, lng: -75.0627 },
  { lat: 40.4898, lng: -75.0613 },
  { lat: 40.5733, lng: -75.1067 },
  { lat: 40.5639, lng: -75.2138 },
  { lat: 40.6192, lng: -75.2028 },
  { lat: 40.6494, lng: -75.2069 },
  { lat: 40.8284, lng: -75.0806 },
  { lat: 40.8429, lng: -75.0998 },
  { lat: 40.8689, lng: -75.0504 },
  { lat: 40.9913, lng: -75.1369 },
  { lat: 41.2293, lng: -74.8677 },
  { lat: 41.3479, lng: -74.7537 },
  { lat: 41.3469, lng: -74.7249 },
  { lat: 41.3593, lng: -74.696 },
];

export default NJ;
