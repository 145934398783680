const PA = [
  { lat: 42.3261, lng: -80.5174 },
  { lat: 42.3961, lng: -80.0821 },
  { lat: 42.5167, lng: -79.7621 },
  { lat: 42.0003, lng: -79.7607 },
  { lat: 41.9983, lng: -75.358 },
  { lat: 41.9431, lng: -75.2673 },
  { lat: 41.8696, lng: -75.1794 },
  { lat: 41.7713, lng: -75.0586 },
  { lat: 41.6729, lng: -75.0366 },
  { lat: 41.6021, lng: -75.0641 },
  { lat: 41.5086, lng: -74.9927 },
  { lat: 41.4283, lng: -74.7935 },
  { lat: 41.3933, lng: -74.707 },
  { lat: 41.2282, lng: -74.8608 },
  { lat: 40.983, lng: -75.1355 },
  { lat: 40.8554, lng: -75.049 },
  { lat: 40.6806, lng: -75.1904 },
  { lat: 40.5639, lng: -75.2124 },
  { lat: 40.5743, lng: -75.1025 },
  { lat: 40.5013, lng: -75.06 },
  { lat: 40.4208, lng: -75.0655 },
  { lat: 40.4072, lng: -74.9776 },
  { lat: 40.3392, lng: -74.9432 },
  { lat: 40.2628, lng: -74.8389 },
  { lat: 40.1495, lng: -74.7221 },
  { lat: 39.9592, lng: -75.0929 },
  { lat: 39.837, lng: -75.2577 },
  { lat: 39.8128, lng: -75.4321 },
  { lat: 39.8317, lng: -75.6477 },
  { lat: 39.7199, lng: -75.7892 },
  { lat: 39.722, lng: -80.5243 },
  { lat: 42.324, lng: -80.5202 },
];

export default PA;
