import React from "react";
import { motion } from "framer-motion";

const lineVariants = {
  hidden: { width: "0%", opacity: 0 },
  visible: { width: "100%", opacity: 1 },
};

const transition = {
  duration: 0.4,
};

function NavButton({
  onClick,
  label,
  isSelected,
  style = {},
  selectedStyle = {},
}) {
  let styleOverride = {
    ...style,
  };
  if (isSelected) styleOverride = { ...style, ...selectedStyle, opacity: 1 };

  return (
    <button
      className={"nav-button " + label}
      onClick={onClick}
      style={styleOverride}
    >
      {label}
      <motion.div
        initial={"hidden"}
        variants={lineVariants}
        transition={transition}
        animate={isSelected ? "visible" : "hidden"}
        className="underline"
      />
    </button>
  );
}

export default NavButton;
