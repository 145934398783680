export default [
  { lat: 42.0003, lng: -72.7789 },
  { lat: 42.033, lng: -72.7405 },
  { lat: 42.033, lng: -72.3779 },
  { lat: 42.0228, lng: -71.7984 },
  { lat: 42.0085, lng: -71.8011 },
  { lat: 42.0197, lng: -71.385 },
  { lat: 41.8961, lng: -71.3837 },
  { lat: 41.8982, lng: -71.3411 },
  { lat: 41.8358, lng: -71.337 },
  { lat: 41.8245, lng: -71.3493 },
  { lat: 41.7816, lng: -71.3342 },
  { lat: 41.7529, lng: -71.2628 },
  { lat: 41.6719, lng: -71.1914 },
  { lat: 41.6616, lng: -71.1351 },
  { lat: 41.6124, lng: -71.1433 },
  { lat: 41.5939, lng: -71.131 },
  { lat: 41.4973, lng: -71.1214 },
  { lat: 41.3149, lng: -71.0266 },
  { lat: 41.159, lng: -70.8316 },
  { lat: 41.1662, lng: -69.9225 },
  { lat: 41.3201, lng: -69.7948 },
  { lat: 41.8133, lng: -69.7398 },
  { lat: 42.1939, lng: -70.0337 },
  { lat: 42.2173, lng: -70.5144 },
  { lat: 42.4133, lng: -70.6984 },
  { lat: 42.642, lng: -70.3647 },
  { lat: 42.8286, lng: -70.4759 },
  { lat: 42.876, lng: -70.6133 },
  { lat: 42.8619, lng: -70.844 },
  { lat: 42.889, lng: -70.9154 },
  { lat: 42.8075, lng: -71.0651 },
  { lat: 42.8226, lng: -71.1337 },
  { lat: 42.7873, lng: -71.1859 },
  { lat: 42.7369, lng: -71.1832 },
  { lat: 42.747, lng: -71.2189 },
  { lat: 42.74, lng: -71.256 },
  { lat: 42.6986, lng: -71.2985 },
  { lat: 42.7127, lng: -71.9151 },
  { lat: 42.7309, lng: -72.5441 },
  { lat: 42.745, lng: -73.2541 },
  { lat: 42.746, lng: -73.2664 },
  { lat: 42.546, lng: -73.3406 },
  { lat: 42.2671, lng: -73.4436 },
  { lat: 42.1349, lng: -73.4917 },
  { lat: 42.088, lng: -73.5081 },
  { lat: 42.0483, lng: -73.4985 },
  { lat: 42.0452, lng: -73.1841 },
  { lat: 42.0371, lng: -72.8146 },
  { lat: 41.9962, lng: -72.816 },
  { lat: 42.0024, lng: -72.7803 },
];
