import React from "react";
import styled from "styled-components";
import BenefitItem from "./BenefitItem";
import content from "./content";

function Benefits({ contactRef }) {
  const renderContent = () => {
    return content.map((el, i) => {
      return <BenefitItem content={el} key={i} />;
    });
  };
  return (
    <section className="page-content-wrapper" style={{ background: "#f5f5f5" }}>
      <StyledBenefits>
        <div className="header-wrapper">
          <h2>Enjoy the Benefits of</h2>
          <h2>Working With Momentum</h2>
        </div>
        <div className="benefits-items">{renderContent()}</div>
        <div className="footer-wrapper">
          <p>
            To learn about our comprehensive list of services, please
            <a
              className="link"
              href="_"
              onClick={(e) => {
                e.preventDefault();
                contactRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              contact us
            </a>
          </p>
          <p>
            or email
            <a className="link" href="mailto:info@mrs.com">
              info@mrs.com
            </a>
          </p>
        </div>
      </StyledBenefits>
    </section>
  );
}

export default Benefits;

const StyledBenefits = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;

  .footer-wrapper {
    text-align: center;
    margin-bottom: 1em;
  }

  .link {
    margin-left: 0.4ch;
  }

  .benefits-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2em;

    .item-wrapper {
      margin: auto;
      width: 30%;
      min-width: 150px;
      display: flex;
      flex-direction: row;
      margin-top: 4em;
      flex-shrink: 1;

      .icon-wrapper {
        width: 80px;
        height: 80px;
        aspect-ratio: 1;
      }

      .text-wrapper {
        padding-left: 1em;
      }
    }
  }

  @media (max-width: 1220px) {
    .item-wrapper {
      margin-top: 2em;
    }

    .icon-wrapper {
      max-width: 48px;
      max-height: 48px;
      aspect-ratio: 1;
    }
  }

  @media (max-width: 768px) {
    .item-wrapper {
      margin-top: 1em;
      align-items: center;
      height: 200px;
    }

    .icon-wrapper {
      max-width: 32px;
      max-height: 32px;
      aspect-ratio: 1;
    }
  }
`;
