import React from "react";
import styled from "styled-components";

import BIA from "../assets/safety/BIA.png";
import OSHA from "../assets/safety/OSHA.png";
import NRCA from "../assets/safety/NRCA.png";

import { ReactComponent as RoofBackground } from "../assets/safety/RoofBackground.svg";

function Safety() {
  return (
    <div className="page-background">
      <RoofBackground
        style={{
          position: "absolute",
          background: "#F7FCFF",
          bottom: 0,
          left: 0,
          height: "100%",
          width: "120%",
        }}
      />
      <section className="page-content-wrapper">
        <StyledSafety>
          <div className="header-wrapper">
            <h2>Our Safety and Responsibility</h2>
            <p className="large-text">
              Momentum Solar + Roofing is dedicated to keep a safe working
              environment that
            </p>
            <p className="large-text">
              is conducive to exception performance in the field.
            </p>
          </div>
          <div className="images-wrapper">
            <img src={BIA} alt="Building Industry Association (BIA) Logo" />
            <img
              src={NRCA}
              alt="National Roofing Contractors Association (NRCA) Logo"
            />
            <img
              src={OSHA}
              alt="Occupational Safety and Health Administration (OSHA) Logo"
            />
          </div>
        </StyledSafety>
      </section>
    </div>
  );
}

export default Safety;

const StyledSafety = styled.div`
  p {
    margin-top: 1em;
  }

  .images-wrapper {
    display: flex;
    padding: 3em;
    justify-content: space-evenly;
    margin-top: 5em;

    img {
      width: 30%;
      max-height: 8em;
      text-align: center;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    .images-wrapper {
      flex-direction: column;
      justify-content: space-between;
      margin: 0;

      img {
        width: 100%;
        margin-top: 2em;
      }
    }
  }
`;
