export const pins = [
  //CA

  //AZ

  { lat: 33.436475580851, lng: -112.06793889999209 }, // Phoenix - WH
  //TX
  // { lat: 30.336609802479337, lng: -97.65747840189086 }, // Austin - WH/Ops
  { lat: 30.33663758178104, lng: -97.6574676730544 }, // Austin - Sales
  { lat: 29.540826116209534, lng: -98.37010967306696 }, // San Antonio
  { lat: 32.94271886652319, lng: -97.0225994423187 }, // Dallas
  { lat: 29.840840750421258, lng: -95.56559453073521 }, // Houston
  { lat: 31.744262560994475, lng: -106.308568473542 }, // El Paso
  //FL
  // { lat: 28.480393312918988, lng: -81.29945273104907 }, // Orlando - WH/Ops
  { lat: 28.478324811339323, lng: -81.45522706593889 }, // Orlando - Sales
  { lat: 28.444268780554065, lng: -81.41449918657426 }, // Orlando - Sales NEW
  { lat: 26.19862450611798, lng: -80.28904039303427 }, // Tamarac
  { lat: 26.576355173711555, lng: -81.87818037340786 }, // FT Myers - Sales/Ops
  // { lat: 28.007916538066898, lng: -82.54133067309014 }, // Tampa - WH/Ops
  { lat: 28.005895082673906, lng: -82.540543131057 }, // Tampa - Sales
  // { lat: 30.22470369282979, lng: -81.7030283310187 }, // Jacksonville - WH
  { lat: 30.20185852680186, lng: -81.74422064254463 }, // Jacksonville - Sales/Ops
  { lat: 27.270731319316038, lng: -80.29538837329207 }, // Port St. Lucie - Sales
  // PA
  { lat: 40.05462379951141, lng: -76.28302355905367 }, // Lancaster
  //NV
  { lat: 36.07740948901268, lng: -115.19413788979514 }, //Las Vegas
  // NJ
  { lat: 40.53172401033639, lng: -74.3721264 }, // Metuchen
  { lat: 40.55917392725111, lng: -74.4314342191006 }, // South Plainfield
  { lat: 40.57910185435175, lng: -74.42833673345781 }, // South Plainfield - WH
  { lat: 39.907912319752405, lng: -74.96838447300014 }, // Cherry Hill
  { lat: 40.150460123181595, lng: -74.09674390455861 }, // Wall - Sales
  // NY
  { lat: 40.79590949062602, lng: -73.46243325948437 }, // Plainview
  { lat: 40.61394484615415, lng: -74.17697855948933 }, // Staten Island - Sales
  { lat: 40.75486167932732, lng: -73.92921521530512 }, // Long Island City - Salesv
  // CT
  // MA
  { lat: 42.315647576616726, lng: -71.58059321834878 },
  //CA
  { lat: 33.7958, lng: -117.8587 }, // Orange
];

export const roofPins = [
  // { lat: 36.07750425031517, lng: -115.19416777323022 }, // Las Vegas
  { lat: 33.7956, lng: -117.8586 }, // Orange
  // { lat: 33.41243931084478, lng: -111.98189062883648 }, // Phoenix - Sales/Ops
  { lat: 38.655591128908526, lng: -121.48905093068814 }, // Sacramento
];
