import Westlake from "../../assets/products/Westlake.png";
import Eagle from "../../assets/products/Eagle.png";
import Ludowici from "../../assets/products/Ludowici.png";
import USTile from "../../assets/products/USTile.png";
import MCA from "../../assets/products/MCA.png";
import Redland from "../../assets/products/Redland.png";
import GAF from "../../assets/products/GAF.png";
import CertainTeed from "../../assets/products/CertainTeed.png";
import OwensCorning from "../../assets/products/OwensCorning.png";
import IB from "../../assets/products/IB.png";
import Versico from "../../assets/products/Versico.png";
import JohnsManville from "../../assets/products/JohnsManville.png";
import Carlisle from "../../assets/products/Carlisle.png";
import Taylor from "../../assets/products/Taylor.png";
import AEPSpan from "../../assets/products/AEPSpan.png";
import MSMetalSales from "../../assets/products/MSMetalSales.png";
import CustomBuiltStructures from "../../assets/products/CustomBuiltStructures.png";
import Malarky from "../../assets/products/Malarky.png";
import LGSolar from "../../assets/products/LGSolar.png";
import Hanwha from "../../assets/products/Hanwha.png";

const content = [
  {
    label: "Tile Roofing",
    icons: [
      { src: Westlake, name: "Westlake Royal Roofing Solutions" },
      { src: Eagle, name: "Eagle Roofing Products" },
      { src: Ludowici, name: "Ludowici Roof Tile" },
      { src: USTile, name: "US Tile by Boral" },
      { src: MCA, name: "MCA Clay Roof Tile" },
      { src: Redland, name: "Redland Clay Tile" },
    ],
  },
  {
    label: "Asphalt Shingles",
    // IMPORTANT!! The lengths of these arrays need to be greater than 5 to loop correctly on large screens.
    // see `const STAGGER = DURATION / 5;` in /Components/Products/Slideshow.js
    icons: [
      { src: GAF, name: "GAF Roofing" },
      { src: CertainTeed, name: "Certain Teed" },
      { src: OwensCorning, name: "Owens Corning" },
      { src: GAF, name: "GAF Roofing" },
      { src: CertainTeed, name: "Certain Teed" },
      { src: OwensCorning, name: "Owens Corning" },
    ],
  },
  {
    label: "TOP and PVC Roofing*",
    icons: [
      { src: GAF, name: "GAF Roofing" },
      { src: IB, name: "IB Roofing Systems" },
      { src: Versico, name: "Versico Roofing Systems" },
      { src: JohnsManville, name: "Johns Manville" },
      { src: Carlisle, name: "Carlisle SynTech Systems" },
    ],
  },
  {
    label: "Metal Roofing",
    icons: [
      { src: Taylor, name: "Taylor Metal Products" },
      { src: AEPSpan, name: "AEP Span" },
      { src: MSMetalSales, name: "MS Metal Sales" },
      { src: CustomBuiltStructures, name: "Custom Built Structures" },
    ],
  },
  {
    label: "Built-Up / Cold-Applied Roofing",
    icons: [
      { src: JohnsManville, name: "Johns Manville" },
      { src: GAF, name: "GAF Roofing" },
      { src: CertainTeed, name: "Certain Teed" },
      { src: Malarky, name: "Malarky Roofing Products" },
    ],
  },
  {
    label: "Solar Installation",
    icons: [
      { src: LGSolar, name: "LG Solar" },
      { src: Hanwha, name: "Hanwha" },
      { src: LGSolar, name: "LG Solar" },
      { src: Hanwha, name: "Hanwha" },
    ],
  },
];

export default content;
