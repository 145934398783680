import React from "react";
import styled from "styled-components";

function Button({
  labelStyle,
  label = "Submit",
  type = 1,
  color = "#1E95CF",
  children,
  style = {},
  ...props
}) {
  const buttonStyle = { ...style };
  switch (type) {
    case 1:
    default:
      buttonStyle.borderColor = color;
      buttonStyle.color = color;
      break;
    case 2:
      buttonStyle.borderColor = color;
      buttonStyle.background = color;
      buttonStyle.color = "#fff";
      break;
  }
  return (
    <StyledButton style={buttonStyle} {...props}>
      <p style={{ color: buttonStyle.color, margin: 0, ...labelStyle }}>
        {children || label}
      </p>
    </StyledButton>
  );
}

export default Button;

const StyledButton = styled.button`
  border: solid 2px;
  border-radius: 8px;
  background: transparent;
  padding: 16px 32px;
  margin: auto;
  font-family: LatoBold;
  font-size: 1rem;
  cursor: pointer;

  p {
    white-space: nowrap;
    font-weight: bolder;
    font-family: Lato;
  }

  :hover {
    opacity: 0.6;
  }
`;
