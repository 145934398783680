import React from "react";
import styled from "styled-components";

import { ReactComponent as Pin } from "../../assets/locations/Pin.svg";
import { ReactComponent as RoofPin } from "../../assets/locations/RoofPin.svg";

function MapKey() {
  return (
    <StyledMapKey>
      <div className="row">
        <Pin className="icon" />
        <p className="key-text">Momentum Solar Location</p>
      </div>
      <div className="row">
        <RoofPin className="icon" />
        <p className="key-text">Roofing & Solar Location</p>
      </div>
      <div className="row">
        <div className="dot-wrapper">
          <div className="dot" style={{ background: "grey" }} />
        </div>
        <p className="key-text">Momentum Solar Territory</p>
      </div>
      <div className="row">
        <div className="dot-wrapper">
          <div className="dot" style={{ background: "#336699" }} />
        </div>
        <p className="key-text">Roofing & Solar Territory</p>
      </div>
      <div className="row">
        <div className="dot-wrapper">
          <div className="dot" style={{ background: "#94dbff" }} />
        </div>
        <p className="key-text">Upcoming Market</p>
      </div>
    </StyledMapKey>
  );
}

export default MapKey;

const StyledMapKey = styled.div`
  background: white;
  position: absolute;
  z-index: 100;
  top: 0;
  padding: 0.5em;
  margin: 0.5em;

  .row {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    padding: 1px;
  }

  .icon {
    background: lightgrey;
    border-radius: 100px;
    padding: 1px;
    height: 20px;
    width: 20px;
  }

  .key-text {
    margin-left: 0.5em;
  }

  .dot {
    min-height: 8px;
    min-width: 8px;
    border-radius: 100px;
  }

  .dot-wrapper {
    width: 22px;
    min-width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
