import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

import DRHorton from "../assets/partners/DRHorton.png";
import Lennar from "../assets/partners/Lennar.png";
import Meritage from "../assets/partners/Meritage.png";
import Richmond from "../assets/partners/Richmond.png";
import TollBrothers from "../assets/partners/TollBrothers.png";
import TriPointe from "../assets/partners/TriPointe.png";

const images = [
  {
    src: Lennar,
    left: 0,
    top: 0,
    name: "Lennar",
  },
  { src: Richmond, bottom: 5, left: 5, name: "Richmond American Homes" },
  { src: TollBrothers, left: 20, top: 35, name: "Toll Brothers" },
  { src: DRHorton, left: 55, top: 5, name: "D.R. Horton" },
  { src: Meritage, bottom: 0, right: 20, name: "Meritage Homes" },
  { src: TriPointe, right: 0, top: 40, name: "Tri Pointe" },
];

function Partners({ isInView }) {
  const renderImages = () =>
    images.map(({ src, top, left, bottom, right, name }, i) => {
      const initialPosition = {};
      const position = {};
      if (left >= 0) {
        initialPosition.left = left - 5 + "%";
        position.left = left + "%";
      }
      if (right >= 0) {
        initialPosition.right = right + 5 + "%";
        position.right = right + "%";
      }
      if (top >= 0) {
        initialPosition.top = top - 5 + "%";
        position.top = top + "%";
      }
      if (bottom >= 0) {
        initialPosition.bottom = bottom + 5 + "%";
        position.bottom = bottom + "%";
      }

      return (
        <motion.div
          initial={{
            opacity: 0,
            ...initialPosition,
          }}
          animate={{ opacity: 1, ...position }}
          transition={{ duration: 1, delay: i * 0.2 + 1, ease: "easeOut" }}
          key={"partner" + i}
          className="image-wrapper"
        >
          <img
            src={src}
            alt={
              name
                ? `The logo for ${name}, one of our partners`
                : "One of our partners"
            }
          />
        </motion.div>
      );
    });

  return (
    <div className="page-background" style={{ background: "#FAFAFA" }}>
      <section className="page-content-wrapper">
        <StyledProducts>
          <div className="header-wrapper">
            <h2>Trusted Building Partner for</h2>
            <h2>More than 75 Companies Nationwide</h2>
          </div>

          {isInView && <div className={"logos-wrapper"}>{renderImages()}</div>}
        </StyledProducts>
      </section>
    </div>
  );
}

export default Partners;

const StyledProducts = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logos-wrapper {
    display: flex;
    margin: 4em 0;
    height: 40vh;
    min-height: 300px;
    max-height: 500px;

    width: 100%;
    max-width: 900px;

    position: relative;

    .image-wrapper {
      position: absolute;
    }

    img {
      max-width: 30vw;
      max-height: 4em;

      object-fit: contain;
    }
  }
`;
